.footer {
  padding: 60px 20px 40px;
  background-color: #000;

  @include mq(md) {
    padding: 68px 60px 29px;
  }
}

.footer__logo,
.footer__copyright {
  text-align: center;
}

.footer__logo img {
  width: 140px;

  @include mq(md) {
    width: 175px;
  }
}

.footer__copyright {
  margin-top: 15px;
  font-size: 1rem;
  line-height: 2.181;
  color: #fff;

  @include mq(md) {
    font-size: 1.1rem;
  }
}

.footer__copyright a {
  &:hover {
    @include mq(md) {
      text-decoration: underline;
    }
  }
}

.footer__group {
  display: flex;
  justify-content: center;
  margin-top: 40px;

  @include mq(md) {
    justify-content: flex-end;
    margin-top: 5px;
  }
}

.footer__sns {
  display: flex;
  align-items: center;

  &:nth-child(n + 2) {
    margin-left: 20px;
  }
}

.footer__icon {
  //
}

.footer__icon img {
  width: 29px;
}

.footer__icon--twitter img {
  width: 30px;
}

.footer__text {
  margin-left: 6px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #fff;
}

.footer__sns:hover .footer__text {
  @include mq(md) {
    text-decoration: underline;
  }
}
