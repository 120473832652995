.about {
  background-image: url('/assets/images/about_bg.jpg');
  background-position: 50% 0;
  background-size: cover;
}

.about__container {
  padding: 100px 0 70px;
  opacity: 0;
  transition: opacity 1.3s $ease-out-quart;

  @include mq(md) {
    padding: 363px 0 98px;
  }

  &.is-active {
    opacity: 1;
  }
}

.about__heading {
  font-family: 'din-2014', sans-serif;
  color: #f9cd35;
  text-align: center;
}

.about__heading-main {
  padding-left: 5px;
  font-size: 4.2rem;
  letter-spacing: 0.1em;

  @include mq(md) {
    font-size: 6rem;
  }
}

.about__heading-sub {
  display: block;
  margin-top: -5px;
  font-size: 1.8rem;
  letter-spacing: 0;

  @include mq(md) {
    margin-top: -12px;
    font-size: 2.6rem;
  }
}

.about__title {
  margin-top: 15px;
  font-size: 2.2rem;
  font-weight: 600;
  color: #fff;
  text-align: center;
  letter-spacing: 0.05em;

  @include mq(md) {
    margin-top: 18px;
    font-size: 3.4rem;
  }
}

.about__content {
  padding: 0 20px;
  margin-top: 40px;

  @include mq(md) {
    width: 1000px;
    padding: 0;
    margin: 45px auto 0;
  }
}

.about__catch {
  position: relative;
  width: 100%;
  padding: 14px 0 16px;
  margin: 0 auto;
  font-size: 2.8rem;
  font-weight: 600;
  color: #fff;
  text-align: center;

  @include mq(md) {
    width: 753px;
    padding: 16px 0 20px;
    font-size: 5.4rem;
    letter-spacing: 0.04em;
  }

  &::before,
  &::after {
    position: absolute;
    left: 0;
    width: 100%;
    height: 5px;
    content: '';
    background-image: linear-gradient(to left, #ffe412, #9f1e00);

    @include mq(md) {
      height: 7px;
    }
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}

.about__bodies {
  margin-top: 26px;

  @include mq(md) {
    text-align: center;
  }
}

.about__body {
  font-size: 1.5rem;
  line-height: 2;
  color: #fff;

  @include mq(md) {
    font-size: 1.8rem;
    line-height: 2.111;
  }

  &:nth-child(n + 2) {
    margin-top: 9px;
  }
}

.about__images {
  margin-top: 40px;

  @include mq(md) {
    display: flex;
    margin: 44px -15px 0;
  }
}

.about__img {
  opacity: 0;
  transition: opacity 1.3s $ease-out-quart;

  @include mq(md) {
    padding: 0 15px;
  }

  &:nth-child(n + 2) {
    margin-top: 25px;

    @include mq(md) {
      margin-top: 0;
    }
  }

  &.is-active {
    opacity: 1;
  }
}

.about__img img {
  width: 100%;
}
