.about {
  background-image: url("/assets/images/about_bg.jpg");
  background-position: 50% 0;
  background-size: cover;
}

.about__container {
  padding: 100px 0 70px;
  opacity: 0;
  transition: opacity 1.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
@media (min-width: 48em) {
  .about__container {
    padding: 363px 0 98px;
  }
}
.about__container.is-active {
  opacity: 1;
}

.about__heading {
  font-family: "din-2014", sans-serif;
  color: #f9cd35;
  text-align: center;
}

.about__heading-main {
  padding-left: 5px;
  font-size: 4.2rem;
  letter-spacing: 0.1em;
}
@media (min-width: 48em) {
  .about__heading-main {
    font-size: 6rem;
  }
}

.about__heading-sub {
  display: block;
  margin-top: -5px;
  font-size: 1.8rem;
  letter-spacing: 0;
}
@media (min-width: 48em) {
  .about__heading-sub {
    margin-top: -12px;
    font-size: 2.6rem;
  }
}

.about__title {
  margin-top: 15px;
  font-size: 2.2rem;
  font-weight: 600;
  color: #fff;
  text-align: center;
  letter-spacing: 0.05em;
}
@media (min-width: 48em) {
  .about__title {
    margin-top: 18px;
    font-size: 3.4rem;
  }
}

.about__content {
  padding: 0 20px;
  margin-top: 40px;
}
@media (min-width: 48em) {
  .about__content {
    width: 1000px;
    padding: 0;
    margin: 45px auto 0;
  }
}

.about__catch {
  position: relative;
  width: 100%;
  padding: 14px 0 16px;
  margin: 0 auto;
  font-size: 2.8rem;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
@media (min-width: 48em) {
  .about__catch {
    width: 753px;
    padding: 16px 0 20px;
    font-size: 5.4rem;
    letter-spacing: 0.04em;
  }
}
.about__catch::before, .about__catch::after {
  position: absolute;
  left: 0;
  width: 100%;
  height: 5px;
  content: "";
  background-image: linear-gradient(to left, #ffe412, #9f1e00);
}
@media (min-width: 48em) {
  .about__catch::before, .about__catch::after {
    height: 7px;
  }
}
.about__catch::before {
  top: 0;
}
.about__catch::after {
  bottom: 0;
}

.about__bodies {
  margin-top: 26px;
}
@media (min-width: 48em) {
  .about__bodies {
    text-align: center;
  }
}

.about__body {
  font-size: 1.5rem;
  line-height: 2;
  color: #fff;
}
@media (min-width: 48em) {
  .about__body {
    font-size: 1.8rem;
    line-height: 2.111;
  }
}
.about__body:nth-child(n+2) {
  margin-top: 9px;
}

.about__images {
  margin-top: 40px;
}
@media (min-width: 48em) {
  .about__images {
    display: flex;
    margin: 44px -15px 0;
  }
}

.about__img {
  opacity: 0;
  transition: opacity 1.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
@media (min-width: 48em) {
  .about__img {
    padding: 0 15px;
  }
}
.about__img:nth-child(n+2) {
  margin-top: 25px;
}
@media (min-width: 48em) {
  .about__img:nth-child(n+2) {
    margin-top: 0;
  }
}
.about__img.is-active {
  opacity: 1;
}

.about__img img {
  width: 100%;
}

.community {
  background-image: linear-gradient(to left, #ffe412, #f49e34);
}

.community__container {
  padding: 50px 20px 70px;
  opacity: 0;
  transition: opacity 1.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
@media (min-width: 48em) {
  .community__container {
    width: 1000px;
    padding: 76px 0 85px;
    margin: 0 auto;
  }
}
.community__container.is-active {
  opacity: 1;
}

.community__heading {
  font-size: 4.2rem;
  text-align: center;
}
@media (min-width: 48em) {
  .community__heading {
    font-size: 5.7rem;
  }
}

.community__lede {
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
}
@media (min-width: 48em) {
  .community__lede {
    margin-top: -4px;
    font-size: 1.6rem;
  }
}

.community__lede span {
  display: inline-block;
  padding: 2px 20px;
  background-color: #fff;
}
@media (min-width: 48em) {
  .community__lede span {
    padding: 1px 6px 3px;
  }
}

.community__content {
  position: relative;
  padding: 35px 30px 45px;
  margin-top: 32px;
  background-color: #fff;
}
@media (min-width: 48em) {
  .community__content {
    padding: 56px 60px 89px 73px;
  }
}
.community__content::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  content: "";
  background-image: linear-gradient(to left, #9f1e00, #ffe412);
}
@media (min-width: 48em) {
  .community__content::before {
    height: 10px;
  }
}

@media (min-width: 48em) {
  .community__inner {
    display: flex;
  }
}

@media (min-width: 48em) {
  .community__detail {
    width: calc(50% - 30px);
    margin-right: 30px;
  }
}

.community__title {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.6364;
  letter-spacing: 0.05em;
}
@media (min-width: 48em) {
  .community__title {
    margin-left: 5px;
    font-size: 2.2rem;
  }
}

.community__body {
  margin-top: 17px;
  line-height: 1.75;
  letter-spacing: 0.05em;
}

.community__merit {
  margin-top: 20px;
}
@media (min-width: 48em) {
  .community__merit {
    margin-top: 28px;
  }
}

.community__merit-title {
  padding: 4px 0 5px;
  font-weight: 600;
  text-align: center;
  background-color: #fedc15;
}

.community__merit-content {
  padding: 15px 12px 22px 18px;
  border: 1px solid #d6d6d6;
  border-top: 0;
}

.community__merit-list li {
  padding-left: 1.2em;
  font-weight: 600;
  text-indent: -1.2em;
}
.community__merit-list li:nth-child(n+2) {
  margin-top: 12px;
}

.community__merit-list span {
  margin-right: 2px;
  color: #f6aa2e;
}

.community__notes {
  margin-top: 10px;
}

.community__note {
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.6923;
}
@media (min-width: 48em) {
  .community__note {
    font-size: 1.3rem;
  }
}

@media (min-width: 48em) {
  .community__img {
    width: 50%;
  }
}

.community__img img {
  width: 100%;
}

.community__link {
  margin-top: 26px;
  text-align: center;
}

.community__link a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  font-size: 2rem;
  font-weight: 600;
  color: #fff;
  letter-spacing: 0.1em;
  background-image: linear-gradient(to right, #d7521e, #a02f03);
  border-radius: 999px;
}
@media (min-width: 48em) {
  .community__link a {
    position: relative;
    z-index: 1;
    width: 650px;
    height: 100px;
    margin-left: -18px;
    font-size: 3.2rem;
  }
}
@media (min-width: 48em) {
  .community__link a::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #a02f03;
    border-radius: 999px;
    opacity: 0;
    transition: opacity 0.2s;
  }
}
@media (min-width: 48em) {
  .community__link a:hover::before {
    opacity: 1;
  }
}

.footer {
  padding: 60px 20px 40px;
  background-color: #000;
}
@media (min-width: 48em) {
  .footer {
    padding: 68px 60px 29px;
  }
}

.footer__logo,
.footer__copyright {
  text-align: center;
}

.footer__logo img {
  width: 140px;
}
@media (min-width: 48em) {
  .footer__logo img {
    width: 175px;
  }
}

.footer__copyright {
  margin-top: 15px;
  font-size: 1rem;
  line-height: 2.181;
  color: #fff;
}
@media (min-width: 48em) {
  .footer__copyright {
    font-size: 1.1rem;
  }
}

@media (min-width: 48em) {
  .footer__copyright a:hover {
    text-decoration: underline;
  }
}

.footer__group {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
@media (min-width: 48em) {
  .footer__group {
    justify-content: flex-end;
    margin-top: 5px;
  }
}

.footer__sns {
  display: flex;
  align-items: center;
}
.footer__sns:nth-child(n+2) {
  margin-left: 20px;
}

.footer__icon img {
  width: 29px;
}

.footer__icon--twitter img {
  width: 30px;
}

.footer__text {
  margin-left: 6px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #fff;
}

@media (min-width: 48em) {
  .footer__sns:hover .footer__text {
    text-decoration: underline;
  }
}

.gallery {
  background-image: url("/assets/images/gallery_bg.jpg");
  background-size: cover;
}

.gallery__container {
  padding: 50px 20px 70px;
  opacity: 0;
  transition: opacity 1.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
@media (min-width: 48em) {
  .gallery__container {
    width: 1000px;
    padding: 77px 0 124px;
    margin: 0 auto;
  }
}
.gallery__container.is-active {
  opacity: 1;
}

.gallery__heading {
  font-size: 4.2rem;
  color: #fff;
  text-align: center;
}
@media (min-width: 48em) {
  .gallery__heading {
    font-size: 5.7rem;
  }
}

.gallery__img {
  margin-top: 36px;
}

.gallery__img img {
  width: 100%;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
}

.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 15px;
  background-color: #000;
}
@media (min-width: 48em) {
  .header__container {
    height: 80px;
    padding: 0 40px;
  }
}

.header__logo {
  line-height: 0;
}

.header__logo img {
  width: 35px;
}
@media (min-width: 48em) {
  .header__logo img {
    width: 69px;
  }
}

@media (min-width: 48em) {
  .header__block {
    display: flex;
  }
}

.header__list {
  display: none;
}
@media screen and (min-width: 1280px) {
  .header__list {
    display: flex;
    align-items: center;
    margin-right: 40px;
  }
}

@media (min-width: 48em) {
  .header__list-item:nth-child(n+2) {
    margin-left: 27px;
  }
}

@media (min-width: 48em) {
  .header__list-item a {
    position: relative;
    font-size: 1.3rem;
    color: #fff;
  }
}
@media (min-width: 48em) {
  .header__list-item a::before {
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    height: 1px;
    content: "";
    background-color: #fff;
    transition: width 0.25s;
  }
}
@media (min-width: 48em) {
  .header__list-item a:hover::before {
    width: 100%;
  }
}

.header__contact img {
  width: 180px;
}
@media (min-width: 48em) {
  .header__contact img {
    width: 264px;
    transition: transform 0.2s;
  }
}

@media (min-width: 48em) {
  .header__contact:hover img {
    transform: scale(1.03);
  }
}

.member {
  background-color: #fff;
}

.member__container {
  padding: 50px 20px 70px;
  opacity: 0;
  transition: opacity 1.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
@media (min-width: 48em) {
  .member__container {
    width: 1000px;
    padding: 62px 0 90px;
    margin: 0 auto;
  }
}
.member__container.is-active {
  opacity: 1;
}

.member__heading {
  font-size: 4.2rem;
  text-align: center;
}
@media (min-width: 48em) {
  .member__heading {
    font-size: 5.7rem;
  }
}

.member__lede {
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
}
@media (min-width: 48em) {
  .member__lede {
    margin-top: -4px;
    font-size: 1.6rem;
  }
}

.member__lede span {
  display: inline-block;
  padding: 2px 20px;
  background-color: #fedc15;
}
@media (min-width: 48em) {
  .member__lede span {
    padding: 1px 31px 3px;
  }
}

.member__content {
  margin-top: 60px;
}
@media (min-width: 48em) {
  .member__content {
    margin-top: 99px;
  }
}

.member__item {
  position: relative;
  border-top: 1px solid #707070;
  opacity: 0;
  transition: opacity 1.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.member__item::before {
  position: absolute;
  top: -2.5px;
  left: 0;
  width: 100px;
  height: 4px;
  content: "";
  background-color: #fedc15;
}
@media (min-width: 48em) {
  .member__item::before {
    top: -4px;
    width: 159px;
    height: 7px;
  }
}
.member__item:nth-child(n+2) {
  margin-top: 60px;
}
@media (min-width: 48em) {
  .member__item:nth-child(n+2) {
    margin-top: 98px;
  }
}
.member__item.is-active {
  opacity: 1;
}

.member__item-title {
  margin-top: 15px;
  font-size: 2.4rem;
  line-height: 1.68;
}
@media (min-width: 48em) {
  .member__item-title {
    margin-top: 17px;
    font-size: 3.2rem;
  }
}

.member__block {
  display: flex;
  flex-wrap: wrap;
  margin: 23px -7px 0;
}

.member__inner {
  width: 50%;
  padding: 0 7px;
}
@media (min-width: 48em) {
  .member__inner {
    width: calc(100% / 4);
  }
}
.member__inner:nth-child(n+3) {
  margin-top: 40px;
}
@media (min-width: 48em) {
  .member__inner:nth-child(n+3) {
    margin-top: 0;
  }
}
@media (min-width: 48em) {
  .member__inner:nth-child(n+5) {
    margin-top: 44px;
  }
}

.member__img img {
  width: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.member__group {
  margin-top: 15px;
}

.member__name {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.4;
}
@media (min-width: 48em) {
  .member__name {
    font-size: 1.8rem;
  }
}

.member__company {
  margin-top: 6px;
  font-size: 1rem;
  line-height: 1.3333;
  letter-spacing: 0;
}
@media (min-width: 48em) {
  .member__company {
    font-size: 1.2rem;
  }
}

.member__list {
  display: flex;
  margin-top: 10px;
}
@media (min-width: 48em) {
  .member__list {
    margin-top: 14px;
  }
}

.member__list-item:nth-child(n+2) {
  margin-left: 5px;
}

@media (min-width: 48em) {
  .member__link {
    transition: opacity 0.2s;
  }
}
@media (min-width: 48em) {
  .member__link:hover {
    opacity: 0.8;
  }
}

.member__link img {
  width: 18px;
}
@media (min-width: 48em) {
  .member__link img {
    width: 24px;
  }
}

.movie {
  background-image: url("/assets/images/movie_bg.jpg");
  background-position: 50%;
  background-size: cover;
}

.movie__container {
  padding: 50px 20px 60px;
  opacity: 0;
  transition: opacity 1.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
@media (min-width: 48em) {
  .movie__container {
    padding: 50px 0 66px;
  }
}
.movie__container.is-active {
  opacity: 1;
}

.movie__logo {
  position: relative;
  z-index: 1;
  text-align: center;
}

.movie__logo img {
  width: 130px;
}
@media (min-width: 48em) {
  .movie__logo img {
    width: 238px;
  }
}

.movie__content {
  position: relative;
  margin-top: -18px;
}
@media (min-width: 48em) {
  .movie__content {
    width: 800px;
    margin: -35px auto 0;
  }
}

.movie__content iframe {
  width: 100%;
  height: 250px;
}
@media (min-width: 48em) {
  .movie__content iframe {
    height: 480px;
  }
}

.movie__body {
  margin-top: 20px;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0;
}
@media (min-width: 48em) {
  .movie__body {
    margin-top: 28px;
    font-size: 1.8rem;
  }
}

.movie__buttons {
  margin-top: 15px;
}
@media (min-width: 48em) {
  .movie__buttons {
    display: flex;
    width: 800px;
    margin: 14px auto 0;
  }
}

.movie__button {
  display: block;
}
.movie__button:nth-child(n+2) {
  margin-top: 12px;
}
@media (min-width: 48em) {
  .movie__button:nth-child(n+2) {
    margin-top: 0;
    margin-left: 20px;
  }
}

.movie__button img {
  width: 100%;
}
@media (min-width: 48em) {
  .movie__button img {
    transition: transform 0.2s;
  }
}

@media (min-width: 48em) {
  .movie__button:hover img {
    transform: scale(1.03);
  }
}

.mv {
  position: relative;
  opacity: 0;
  transition: opacity 2s;
}
@media (min-width: 48em) {
  .mv {
    margin-top: 80px;
  }
}
.mv.is-active {
  opacity: 1;
}

.mv__img img {
  width: 100%;
}

.mv__logo {
  position: absolute;
  bottom: 47.21284615%;
  left: 50%;
  width: 64%;
  opacity: 0;
  transition: opacity 1.5s 1.5s;
  transform: translateX(-50%);
}
@media (min-width: 48em) {
  .mv__logo {
    bottom: 60px;
    left: 71px;
    width: 27.9861%;
    transform: translateX(0);
  }
}

.mv.is-active .mv__logo {
  opacity: 1;
}

.mv__logo img {
  width: 100%;
}

.mv__catch {
  position: absolute;
  right: 50%;
  bottom: 7.0486784393%;
  width: 81%;
  opacity: 0;
  transition: opacity 1.5s 2.3s;
  transform: translateX(50%);
}
@media (min-width: 48em) {
  .mv__catch {
    right: 77px;
    bottom: 84px;
    width: 39.0972%;
    transform: translateX(0);
  }
}

.mv.is-active .mv__catch {
  opacity: 1;
}

.mv__catch img {
  width: 100%;
}

.news {
  background-color: #f5f5f5;
}

.news__container {
  padding: 50px 20px 70px;
  opacity: 0;
  transition: opacity 1.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
@media (min-width: 48em) {
  .news__container {
    width: 1000px;
    padding: 62px 0 106px;
    margin: 0 auto;
  }
}
.news__container.is-active {
  opacity: 1;
}

.news__heading {
  font-size: 4.2rem;
  text-align: center;
}
@media (min-width: 48em) {
  .news__heading {
    font-size: 5.7rem;
  }
}

.news__lede {
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
}
@media (min-width: 48em) {
  .news__lede {
    margin-top: -4px;
    font-size: 1.6rem;
  }
}

.news__lede span {
  display: inline-block;
  padding: 2px 20px;
  background-color: #fedc15;
}
@media (min-width: 48em) {
  .news__lede span {
    padding: 1px 31px 3px;
  }
}

.news__content {
  margin-top: 35px;
}
@media (min-width: 48em) {
  .news__content {
    display: flex;
    flex-wrap: wrap;
    margin: 37px -15px 0;
  }
}

@media (min-width: 48em) {
  .news__item {
    width: calc(100% / 3);
    padding: 0 15px;
  }
}
.news__item:nth-child(n+2) {
  margin-top: 40px;
}
@media (min-width: 48em) {
  .news__item:nth-child(n+2) {
    margin-top: 0;
  }
}

.news__img img {
  width: 100%;
}

.news__block {
  margin-top: 20px;
}

.news__time {
  font-size: 1.1rem;
  font-weight: 600;
  color: #9a9a9a;
  letter-spacing: 0.02em;
}
@media (min-width: 48em) {
  .news__time {
    font-size: 1.3rem;
  }
}

.news__body {
  margin-top: 12px;
  line-height: 1.625;
  letter-spacing: 0.02em;
}
@media (min-width: 48em) {
  .news__body {
    min-height: 78px;
  }
}

.news__link {
  margin-top: 25px;
  text-align: center;
}

.news__link a {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 50px;
  font-weight: 600;
  color: #fff;
  background-image: linear-gradient(to right, #d7521e, #a02f03);
  border-radius: 999px;
}
@media (min-width: 48em) {
  .news__link a::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #a02f03;
    border-radius: 999px;
    opacity: 0;
    transition: opacity 0.2s;
  }
}
@media (min-width: 48em) {
  .news__link a:hover::before {
    opacity: 1;
  }
}

.other {
  display: none;
}
@media (min-width: 48em) {
  .other {
    position: fixed;
    right: 30px;
    bottom: 30px;
    display: block;
    width: 65px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    opacity: 0;
    transition: opacity 0.2s;
  }
}
@media (min-width: 48em) {
  .other.is-active {
    opacity: 1;
  }
}
@media (min-width: 48em) {
  .other.is-hide {
    opacity: 0;
  }
}

@media (min-width: 48em) {
  .other__pagetop {
    position: relative;
    display: block;
    padding-top: 32px;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.166;
    color: #262626;
    text-align: center;
  }
}
@media (min-width: 48em) {
  .other__pagetop::before {
    position: absolute;
    top: 10px;
    left: 50%;
    content: "";
    border-color: transparent transparent #d34f1c transparent;
    border-style: solid;
    border-width: 0 13.5px 16px 13.5px;
    transform: translateX(-50%);
  }
}

@media (min-width: 48em) {
  .other__sns {
    padding: 9px 12px 14px;
  }
}

@media (min-width: 48em) {
  .other__link {
    display: block;
    font-size: 0.9rem;
    font-weight: 600;
    color: #242424;
    text-align: center;
    letter-spacing: 0;
  }
}
@media (min-width: 48em) {
  .other__link:nth-child(n+2) {
    margin-top: 5px;
  }
}

@media (min-width: 48em) {
  .other__icon img {
    width: 100%;
  }
}