.community {
  background-image: linear-gradient(to left, #ffe412, #f49e34);
}

.community__container {
  padding: 50px 20px 70px;
  opacity: 0;
  transition: opacity 1.3s $ease-out-quart;

  @include mq(md) {
    width: 1000px;
    padding: 76px 0 85px;
    margin: 0 auto;
  }

  &.is-active {
    opacity: 1;
  }
}

.community__heading {
  font-size: 4.2rem;
  text-align: center;

  @include mq(md) {
    font-size: 5.7rem;
  }
}

.community__lede {
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;

  @include mq(md) {
    margin-top: -4px;
    font-size: 1.6rem;
  }
}

.community__lede span {
  display: inline-block;
  padding: 2px 20px;
  background-color: #fff;

  @include mq(md) {
    padding: 1px 6px 3px;
  }
}

.community__content {
  position: relative;
  padding: 35px 30px 45px;
  margin-top: 32px;
  background-color: #fff;

  @include mq(md) {
    padding: 56px 60px 89px 73px;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    content: '';
    background-image: linear-gradient(to left, #9f1e00, #ffe412);

    @include mq(md) {
      height: 10px;
    }
  }
}

.community__inner {
  @include mq(md) {
    display: flex;
  }
}

.community__detail {
  @include mq(md) {
    width: calc(50% - 30px);
    margin-right: 30px;
  }
}

.community__title {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.6364;
  letter-spacing: 0.05em;

  @include mq(md) {
    margin-left: 5px;
    font-size: 2.2rem;
  }
}

.community__body {
  margin-top: 17px;
  line-height: 1.75;
  letter-spacing: 0.05em;
}

.community__merit {
  margin-top: 20px;

  @include mq(md) {
    margin-top: 28px;
  }
}

.community__merit-inner {
  //
}

.community__merit-title {
  padding: 4px 0 5px;
  font-weight: 600;
  text-align: center;
  background-color: #fedc15;
}

.community__merit-content {
  padding: 15px 12px 22px 18px;
  border: 1px solid #d6d6d6;
  border-top: 0;
}

.community__merit-list {
  //
}

.community__merit-list li {
  padding-left: 1.2em;
  font-weight: 600;
  text-indent: -1.2em;

  &:nth-child(n + 2) {
    margin-top: 12px;
  }
}

.community__merit-list span {
  margin-right: 2px;
  color: #f6aa2e;
}

.community__notes {
  margin-top: 10px;
}

.community__note {
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.6923;

  @include mq(md) {
    font-size: 1.3rem;
  }
}

.community__img {
  @include mq(md) {
    width: 50%;
  }
}

.community__img img {
  width: 100%;
}

.community__link {
  margin-top: 26px;
  text-align: center;
}

.community__link a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  font-size: 2rem;
  font-weight: 600;
  color: #fff;
  letter-spacing: 0.1em;
  background-image: linear-gradient(to right, #d7521e, #a02f03);
  border-radius: 999px;

  @include mq(md) {
    position: relative;
    z-index: 1;
    width: 650px;
    height: 100px;
    margin-left: -18px;
    font-size: 3.2rem;
  }

  &::before {
    @include mq(md) {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: '';
      background-color: #a02f03;
      border-radius: 999px;
      opacity: 0;
      transition: opacity 0.2s;
    }
  }

  &:hover {
    &::before {
      @include mq(md) {
        opacity: 1;
      }
    }
  }
}
