.member {
  background-color: #fff;
}

.member__container {
  padding: 50px 20px 70px;
  opacity: 0;
  transition: opacity 1.3s $ease-out-quart;

  @include mq(md) {
    width: 1000px;
    padding: 62px 0 90px;
    margin: 0 auto;
  }

  &.is-active {
    opacity: 1;
  }
}

.member__heading {
  font-size: 4.2rem;
  text-align: center;

  @include mq(md) {
    font-size: 5.7rem;
  }
}

.member__lede {
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;

  @include mq(md) {
    margin-top: -4px;
    font-size: 1.6rem;
  }
}

.member__lede span {
  display: inline-block;
  padding: 2px 20px;
  background-color: #fedc15;

  @include mq(md) {
    padding: 1px 31px 3px;
  }
}

.member__content {
  margin-top: 60px;

  @include mq(md) {
    margin-top: 99px;
  }
}

.member__item {
  position: relative;
  border-top: 1px solid #707070;
  opacity: 0;
  transition: opacity 1.3s $ease-out-quart;

  &::before {
    position: absolute;
    top: -2.5px;
    left: 0;
    width: 100px;
    height: 4px;
    content: '';
    background-color: #fedc15;

    @include mq(md) {
      top: -4px;
      width: 159px;
      height: 7px;
    }
  }

  &:nth-child(n + 2) {
    margin-top: 60px;

    @include mq(md) {
      margin-top: 98px;
    }
  }

  &.is-active {
    opacity: 1;
  }
}

.member__item-title {
  margin-top: 15px;
  font-size: 2.4rem;
  line-height: 1.68;

  @include mq(md) {
    margin-top: 17px;
    font-size: 3.2rem;
  }
}

.member__block {
  display: flex;
  flex-wrap: wrap;
  margin: 23px -7px 0;
}

.member__inner {
  width: 50%;
  padding: 0 7px;

  @include mq(md) {
    width: calc(100% / 4);
  }

  &:nth-child(n + 3) {
    margin-top: 40px;

    @include mq(md) {
      margin-top: 0;
    }
  }

  &:nth-child(n + 5) {
    @include mq(md) {
      margin-top: 44px;
    }
  }
}

.member__img {
  //
}

.member__img img {
  width: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.member__group {
  margin-top: 15px;
}

.member__name {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.4;

  @include mq(md) {
    font-size: 1.8rem;
  }
}

.member__company {
  margin-top: 6px;
  font-size: 1rem;
  line-height: 1.3333;
  letter-spacing: 0;

  @include mq(md) {
    font-size: 1.2rem;
  }
}

.member__list {
  display: flex;
  margin-top: 10px;

  @include mq(md) {
    margin-top: 14px;
  }
}

.member__list-item {
  &:nth-child(n + 2) {
    margin-left: 5px;
  }
}

.member__link {
  @include mq(md) {
    transition: opacity 0.2s;
  }

  &:hover {
    @include mq(md) {
      opacity: 0.8;
    }
  }
}

.member__link img {
  width: 18px;

  @include mq(md) {
    width: 24px;
  }
}
