.news {
  background-color: #f5f5f5;
}

.news__container {
  padding: 50px 20px 70px;
  opacity: 0;
  transition: opacity 1.3s $ease-out-quart;

  @include mq(md) {
    width: 1000px;
    padding: 62px 0 106px;
    margin: 0 auto;
  }

  &.is-active {
    opacity: 1;
  }
}

.news__heading {
  font-size: 4.2rem;
  text-align: center;

  @include mq(md) {
    font-size: 5.7rem;
  }
}

.news__lede {
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;

  @include mq(md) {
    margin-top: -4px;
    font-size: 1.6rem;
  }
}

.news__lede span {
  display: inline-block;
  padding: 2px 20px;
  background-color: #fedc15;

  @include mq(md) {
    padding: 1px 31px 3px;
  }
}

.news__content {
  margin-top: 35px;

  @include mq(md) {
    display: flex;
    flex-wrap: wrap;
    margin: 37px -15px 0;
  }
}

.news__item {
  @include mq(md) {
    width: calc(100% / 3);
    padding: 0 15px;
  }

  &:nth-child(n + 2) {
    margin-top: 40px;

    @include mq(md) {
      margin-top: 0;
    }
  }
}

.news__img {
  //
}

.news__img img {
  width: 100%;
}

.news__block {
  margin-top: 20px;
}

.news__time {
  font-size: 1.1rem;
  font-weight: 600;
  color: #9a9a9a;
  letter-spacing: 0.02em;

  @include mq(md) {
    font-size: 1.3rem;
  }
}

.news__body {
  margin-top: 12px;
  line-height: 1.625;
  letter-spacing: 0.02em;

  @include mq(md) {
    min-height: 78px;
  }
}

.news__link {
  margin-top: 25px;
  text-align: center;
}

.news__link a {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 50px;
  font-weight: 600;
  color: #fff;
  background-image: linear-gradient(to right, #d7521e, #a02f03);
  border-radius: 999px;

  &::before {
    @include mq(md) {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: '';
      background-color: #a02f03;
      border-radius: 999px;
      opacity: 0;
      transition: opacity 0.2s;
    }
  }

  &:hover {
    &::before {
      @include mq(md) {
        opacity: 1;
      }
    }
  }
}
