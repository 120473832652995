.mv {
  position: relative;
  opacity: 0;
  transition: opacity 2s;

  @include mq(md) {
    margin-top: 80px;
  }

  &.is-active {
    opacity: 1;
  }
}

.mv__img img {
  width: 100%;
}

.mv__logo {
  position: absolute;
  bottom: 47.21284614995864%;
  left: 50%;
  width: 64%;
  opacity: 0;
  transition: opacity 1.5s 1.5s;
  transform: translateX(-50%);

  @include mq(md) {
    bottom: 60px;
    left: 71px;
    width: 27.9861%;
    transform: translateX(0);
  }
}

.mv.is-active .mv__logo {
  opacity: 1;
}

.mv__logo img {
  width: 100%;
}

.mv__catch {
  position: absolute;
  right: 50%;
  bottom: 7.0486784392896%;
  width: 81%;
  opacity: 0;
  transition: opacity 1.5s 2.3s;
  transform: translateX(50%);

  @include mq(md) {
    right: 77px;
    bottom: 84px;
    width: 39.0972%;
    transform: translateX(0);
  }
}

.mv.is-active .mv__catch {
  opacity: 1;
}

.mv__catch img {
  width: 100%;
}
