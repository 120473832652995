.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
}

.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 15px;
  background-color: #000;

  @include mq(md) {
    height: 80px;
    padding: 0 40px;
  }
}

.header__logo {
  line-height: 0;
}

.header__logo img {
  width: 35px;

  @include mq(md) {
    width: 69px;
  }
}

.header__block {
  @include mq(md) {
    display: flex;
  }
}

.header__list {
  display: none;

  @media screen and (min-width: 1280px) {
    display: flex;
    align-items: center;
    margin-right: 40px;
  }
}

.header__list-item {
  &:nth-child(n + 2) {
    @include mq(md) {
      margin-left: 27px;
    }
  }
}

.header__list-item a {
  @include mq(md) {
    position: relative;
    font-size: 1.3rem;
    color: #fff;
  }

  &::before {
    @include mq(md) {
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 0;
      height: 1px;
      content: '';
      background-color: #fff;
      transition: width 0.25s;
    }
  }

  &:hover {
    &::before {
      @include mq(md) {
        width: 100%;
      }
    }
  }
}

.header__contact {
  //
}

.header__contact img {
  width: 180px;

  @include mq(md) {
    width: 264px;
    transition: transform 0.2s;
  }
}

.header__contact:hover img {
  @include mq(md) {
    transform: scale(1.03);
  }
}
