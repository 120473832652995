.other {
  display: none;

  @include mq(md) {
    position: fixed;
    right: 30px;
    bottom: 30px;
    display: block;
    width: 65px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    opacity: 0;
    transition: opacity 0.2s;
  }

  &.is-active {
    @include mq(md) {
      opacity: 1;
    }
  }

  &.is-hide {
    @include mq(md) {
      opacity: 0;
    }
  }
}

.other__pagetop {
  @include mq(md) {
    position: relative;
    display: block;
    padding-top: 32px;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.166;
    color: #262626;
    text-align: center;
  }

  &::before {
    @include mq(md) {
      position: absolute;
      top: 10px;
      left: 50%;
      content: '';
      border-color: transparent transparent #d34f1c transparent;
      border-style: solid;
      border-width: 0 13.5px 16px 13.5px;
      transform: translateX(-50%);
    }
  }
}

.other__sns {
  @include mq(md) {
    padding: 9px 12px 14px;
  }
}

.other__link {
  @include mq(md) {
    display: block;
    font-size: 0.9rem;
    font-weight: 600;
    color: #242424;
    text-align: center;
    letter-spacing: 0;
  }

  &:nth-child(n + 2) {
    @include mq(md) {
      margin-top: 5px;
    }
  }
}

.other__icon {
  //
}

.other__icon img {
  @include mq(md) {
    width: 100%;
  }
}
