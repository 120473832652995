.movie {
  background-image: url('/assets/images/movie_bg.jpg');
  background-position: 50%;
  background-size: cover;
}

.movie__container {
  padding: 50px 20px 60px;
  opacity: 0;
  transition: opacity 1.3s $ease-out-quart;

  @include mq(md) {
    padding: 50px 0 66px;
  }

  &.is-active {
    opacity: 1;
  }
}

.movie__logo {
  position: relative;
  z-index: 1;
  text-align: center;
}

.movie__logo img {
  width: 130px;

  @include mq(md) {
    width: 238px;
  }
}

.movie__content {
  position: relative;
  margin-top: -18px;

  @include mq(md) {
    width: 800px;
    margin: -35px auto 0;
  }
}

.movie__content iframe {
  width: 100%;
  height: 250px;

  @include mq(md) {
    height: 480px;
  }
}

.movie__body {
  margin-top: 20px;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0;

  @include mq(md) {
    margin-top: 28px;
    font-size: 1.8rem;
  }
}

.movie__buttons {
  margin-top: 15px;

  @include mq(md) {
    display: flex;
    width: 800px;
    margin: 14px auto 0;
  }
}

.movie__button {
  display: block;

  &:nth-child(n + 2) {
    margin-top: 12px;

    @include mq(md) {
      margin-top: 0;
      margin-left: 20px;
    }
  }
}

.movie__button img {
  width: 100%;

  @include mq(md) {
    transition: transform 0.2s;
  }
}

.movie__button:hover img {
  @include mq(md) {
    transform: scale(1.03);
  }
}
