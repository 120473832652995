.gallery {
  background-image: url('/assets/images/gallery_bg.jpg');
  background-size: cover;
}

.gallery__container {
  padding: 50px 20px 70px;
  opacity: 0;
  transition: opacity 1.3s $ease-out-quart;

  @include mq(md) {
    width: 1000px;
    padding: 77px 0 124px;
    margin: 0 auto;
  }

  &.is-active {
    opacity: 1;
  }
}

.gallery__heading {
  font-size: 4.2rem;
  color: #fff;
  text-align: center;

  @include mq(md) {
    font-size: 5.7rem;
  }
}

.gallery__img {
  margin-top: 36px;
}

.gallery__img img {
  width: 100%;
}
